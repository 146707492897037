<template>
    <div id="b-invoice" v-if="ready" @contextmenu="handleClick($event)">
        <div class="button-fixed">
            <button type="button" class="btn btn-primary action-button"
                @click="print()">
                <font-awesome-icon icon="print"/>
            </button>
        </div>
        <div class="container container-body border">
            <div class="row mb-5">
                <!--div class="col-12 row">
                    <div class="col-12">
                        <h2>
                            {{getDocName}}
                        </h2>
                    </div>
                </div-->
                <div class="col-6">
                    <div class="col-12">
                        <h2>
                            BALANCE NR. {{invoice.id}}
                        </h2>
                    </div>
                    <div class="col-12">
                        Quarum Travel S.A.
                    </div>
                    <div class="col-12">
                        REG. No. 11.667
                    </div>
                    <div class="col-12">
                        CUIT: 30-70872392-0
                    </div>
                    <div class="col-12">
                        Av. Coronel Díaz 1450 1º A - Bs. As. - Argentina
                    </div>
                    <!--div class="col-12">
                        <a href="mailto:info@nubbo.io">
                            info@nubbo.io
                        </a>
                    </div-->
                    <div class="col-12">
                        Phone: +5411 4827 5700
                    </div>
                </div>
                <div class="col-6 d-flex align-items-center">
                    <div class="col-12 text-right">
                        <img width="200" id="logo" src="@/extra/custom/img/logo-full.png" class="">
                    </div>
                </div>
                <div class="col-8 border-top mt-2" v-if="booking">
                    <div class="col-12" >
                        Id. de cliente: {{booking.PaxName}}
                    </div>
                    <div class="col-12 pl-0" v-if="invoice.Agency">
                        <div class="col-12">
                            <strong>Company:</strong> {{invoice.Agency.Name}}
                        </div>
                        <div class="col-12" v-for="row of agencyAddress">
                            {{row}}
                        </div>
                    </div>
                </div>
                <div class="col-4 border-top mt-2  d-flex align-items-center">
                    <div class="col-12">
                        Date: {{getDate(invoice.Date)}}
                    </div>
                </div>
            </div>
            <div class="row mt-4 border-top div-table">
                <table class="w-100">
                    <thead>
                        <tr>  
                            <td class="p-2 td-border description">Description</td>
                            <td class="p-2 td-border text-right">Unit Price</td>
                            <td class="p-2 td-border text-right">Qty #</td>
                            <td class="p-2 pr-4 text-right">Total</td>
                        </tr>

                    </thead>

                    <tbody class="bnk-dtls">
                        <tr class="" v-for="s of invoice.InvoiceDetails">
                            <td class="p-2 td-border description">
                                <p v-for="row in invoiceDetailsRows(s.Detail)">{{ row }}</p>
                            </td>
                            <td class="p-2 td-border text-right">{{ (s.Amount).toFixed(2) }}</td>
                            <td class="p-2 td-border text-right">{{ s.Pax }}</td>
                            <td class="p-2 td-border text-right">{{ (s.Amount * s.Pax).toFixed(2) }}</td>
                        </tr>
                    </tbody>
                    
                    <tfoot class="border-top">
                        <tr> 
                            <td class="p-2"></td>
                            <td class="p-2"></td>
                            <td class="p-2" style="width: 50%;"></td>
                            <!--td class="p-2 td-border td-border-bottom text-center">TOTAL {{ this.booking.PriceCurrencyId }}</td-->
                            <td class="p-2 td-border td-border-bottom text-right">{{ totalAmount.toFixed(2) }}</td>
                        </tr>
                        <!--tr>
                            <td></td>
                            <td style="width: 50%;"></td>
                            <td class="p-2 td-border td-border-bottom text-center" colspan="2">{{tr('Balance')}}: </td>
                            <td class="p-2 td-border td-border-bottom text-right pr-4">
                                <span v-if="booking.InvoiceBalance">
                                    {{ (booking.InvoiceBalance).toFixed(2) }}
                                </span>
                                <span v-else>
                                    -
                                </span>
                            </td>
                        </tr-->
                    </tfoot>
                </table>
            </div>
            <div class="row mt-2 border-top">
                <div class="col-12">
                    <div class="col-12">
                        Bank Details:
                    </div>
                    <div class="col-12 bnk-dtls" v-html="bankDetails"></div>
                </div>
            </div>
            <div class="row mt-2 border-top">
                <div class="col-12">
                    <div class="col-12">
                        Cancellation Policy
                    </div>
                    <div class="col-12">
                        Up to 25 days before the arrival date: no charge will apply
                    </div>
                    <div class="col-12">
                        25-15 days: 50% of the total amount shall be charged as cancellation fee.
                    </div>
                    <div class="col-12">
                        14-08 days: 70% of the total amount shall be charged as cancellation fee.
                    </div>
                    <div class="col-12">
                        7-0 days: 100% of the total amount shall be charged
                    </div>
                </div>
            </div>
            <div class="row mt-2 border-top">
                <div class="col-12">
                    <div class="col-12">
                        Important:
                    </div>
                    <div class="col-12">
                        DON’T ADD ANY NAME OR ADRESS TO THE ABOVE in order to avoid penalties or surcharges.
                    </div>
                    <div class="col-12">
                        We kindly ask you to send us the copy with swift information to be able to seek for each 
                        transfer quicker, in order to do a correct follow up ofpayments credited in our account
                    </div>
                    <div class="col-12">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'print-invoice',
    props: ['id', 'hash'],
    data () {
        return {
            invoiceURL: null,
            resForm: null,
            booking: null,
            ready: false,
            receipts: [],
            invoice: null,
            defaultBankDetails: "",
            defaultBankDetails: `
            <div >
            Banca Sella - ITALY
            </div>
            <div >
            Quarum Travel S.A.
            </div>
            <div >
            Swift code SELBIT2B
            </div>
            <div >
            IT38E0326822300078896218782
            </div>
            <div >
            USD Account N° 078896218782
            </div>
            <div class="text-danger">
            NOTA BENE: Per favore considerare al momento di effetuare il bonifico di scegliere l´opzione ¨libero di spesse¨
            </div>
        `,
        }
    },
    components: {
    },
    computed:{
        ...mapState({
            user: state => state.main.user,
            recordStore: state => state.main.record,
            processing: state => state.main.processing,
        }),
        bankDetails() {
            return (this.invoice.BankDetails && this.invoice.BankDetails.Details) || this.defaultBankDetails;
        },
        agencyAddress () {
            let res = [];
            if (this.invoice.Agency && this.invoice.Agency.Address) {
                return this.invoice.Agency.Address.split(',');
            }
            return res;
        },
        totalAmount () {
            if (this.invoice) {
                let res = 0
                for (let s of this.invoice.InvoiceDetails) {
                    res += s.Amount * s.Pax;
                }
                return res;
            }
            return 0;
        },
    },
    asyncComputed: {
        async image () {
        }
    },
    async mounted (){
        
        api.setProcessing(true)
        let model = await api.importMixinModule('booking', 'model');
        let r = await api.get('/public/api/get_booking_invoice/' + this.hash + '/' + this.id);
        if (r) {
            this.invoice = r.invoice;
            if (r.booking) {  
                let record = await new model({record: r.booking, notFieldOptions: true});
                this.$store.commit('initRecord', {record});
                this.booking = tools.plainObject(this.recordStore);
            }
            api.setProcessing(false)
            this.ready = true;
        }
    },
    methods: {
        ifText (s) {
            return tools.ifText(s);
        },
        getDate  (d) {
            let r = moment(d).local().locale('en').format('MMM DD, YYYY')
            return r.charAt(0).toUpperCase() + r.slice(1)
        },
        invoiceDetailsRows(detail) {
            return detail.split('\n') || detail;
        },
        print: function() {
            print()
        },
        handleClick (event) {
            this.$root.$children[0].handleClick(event);
        }

    },
    beforeDestroy() {
        this.$store.commit('initRecord', {record: null});
    },

}
</script>

<style scoped>
.lh-1{
    line-height: 1.2;
}
.border-top {
    border-top: solid 2px !important;
}
.border {
    border: solid 2px !important;
}
thead {
    background-color: #06126C;
    color: white
}

table .td-border {
  border-left: 2px solid #000;
}

table .td-border-bottom {
  border-bottom: 2px solid #000;
}

.div-table {
    min-height: 30vh;
}

table tr, thead, td {
    max-height: 30px !important;
}
table {
    height: fit-content;
}

.description {
    width: 80% !important;
}

</style>