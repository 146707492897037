<script>
const bookingNavItems = importVueComp('components/booking', 'BookingNavItems', 'custom');
export default {
  name: 'custom-booking-nav-items',
  extends: bookingNavItems,
  methods: {
    getItems () {
      this.itemsList = this.getItemList();
      this.itemsList.push({
        id: 'invoicing',
        label: 'Invoicing',
        vIf: () => { return this.bookingConfirmed },
        showTabs: () => { return this.showTabs }
      });
      this.setItems();
    }
  }
}
</script>
