
<script>
const bookingTabsContent = importVueComp('components/booking', 'BookingTabsContent', 'custom');
import bookingInvoices from '@/extra/custom/components/booking/BookingInvoices';
export default {
    name: 'custom-booking-tabs-content',
    extends: bookingTabsContent,
    mounted () {
        this.extraTabs.push({id: 'invoicing', label: 'Invoicing', component: bookingInvoices});
    },
}
</script>
