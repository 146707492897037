<template>
    <report-window v-if="ready"
        endpoint="/custom/reports/invoicelist"
        :fields="fields"
        :title="title"
        :templates="templates"
        :hideFilters="options && options.hideFilters"
        :runMounted="options && options.runMounted"
        :cardView="options && options.cardView"
        :hideSearch="options && options.hideSearch"
        :notFilterable="options && options.notFilterable"
        :current.sync="current"
        :headers="headers"
        :totalsOn="totalsOn"
        :columnsClasses="columnsClasses"
        :reportViewClass="options? options.reportViewClass: null"
        :fieldClasses="fieldClasses"
 ></report-window>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
import { mapState } from "vuex";

export default {
    name: "invoices-list",
    props: ["options"],
    components: {
        ReportWindow,
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            adminActions: state => state.main.adminActions,
        }),
        columnsClasses () {
            return {
                TotalAmount: 'text-right',
            }
        },
    },
    mounted () {
        frontTools.setReportValues(this);
        this.ready = true;
    },
    
    data() {
        return {
            current: {},
            ready: false,
            fields: [
                {name: 'FromDate', editor: 'date', label: 'From'},
                {name: 'ToDate', editor: 'date', label: 'To'},
                {name: 'AgencyId', label: 'Agency', editor: 'vue-select', addBlank: true,
                    optionLabels: 'Name', relation: 'agency',
                },
            ],
            templates:[
                {
                    name: 'TotalAmount',
                    callback: (row) => tools.toNumber(row.TotalAmount, 0)
                },

            ],
            title: "Invoice Report",
            headers: {
                InvoiceId: 'Invoice Nr',
                InvoiceDate: 'Date',
                TotalAmount: 'Total',
                File: 'File',
                CurrencyId: 'Currency',
                AgencyId: 'Agency Id'
            },
            totalsOn: ['TotalAmount'],
            fieldClasses: {
            },
        };
    },
    methods: {
    }
};
</script>
